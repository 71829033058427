.dd-container {
    background-color: #fafafa;
    border: 1px solid black;
    margin: 2px;
    padding: 5px;
    display: flex;
    flex-direction: column;
    
}

.dd-container > label {
    font-weight: bold;
    color: #000;
    font-size: 0.7rem;
}

.dd-container > select {
    font-family: var(--primary-font);
    font-weight: bold;
    margin: 2px;
    border: 1px solid #ccc;
    padding: 5px;
    border-radius: 5px;
}

