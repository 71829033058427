.timepicker-container {
    display: flex;
    flex-direction: column;
}

.timepicker-container > label{
 font-weight: bold;
    font-size: 0.6rem;
    margin: 2px;
}

.timepicker-input-area {
    display: flex;
    align-items: center;
}



.timepicker-input-area > input {
    outline: none;
    border: none;
    background-color: var(--background);
    padding: 5px;
    margin-bottom: 5px;
    border-bottom: 1px solid rgb(160, 160, 160);
    font-weight: bold;
    font-family: var(--primary-font);
    width: 100%;
}

.timepicker-input-area >input::placeholder {
    font-weight: bold;
    color: var(--text-light);
    font-family: var(--primary-font);
}

.timepicker-input-area > svg {
    font-size: 2rem;
    padding: 5px;
    border: 2px solid var(--primary-variant);
    color: var(--primary-variant);
    border-radius: 5px;
    margin-bottom: 3px;
    margin-left: 3px;
}

.timepicker-input-area > svg:active {
    background-color: #ccc;
}


