.nav-container {
    display: flex;
    position: relative;
    color: #fff;
    align-items: center;
    background-color: var(--primary);
    font-family: var(--primary-font);
    font-weight: bold;
    font-size: 1.2rem;
}

.nav-container.hide {
    display: none;
}

.nav-item {
    display: flex;
    /* padding: 5px; */
}

.nav-item.header {
    margin-left: auto;
    margin-right: auto;
}

.nav-item.footer {
    font-size: 2rem;
    margin-right: 10px;
    /* margin-top: 10px; */
    cursor: pointer;
    position: absolute;
    right: 0;
}

.nav-item.footer:hover {
    color: #fff;
}