@import url('https://fonts.googleapis.com/css2?family=Arimo:wght@400;500;700&display=swap');

body{

  --primary: #4390bc;
  --secondary: #3d789b;
  --primary-variant: #3d789b;
  --secondary-variant: #451AED;
  --highlight: #2086c2;
  --background: #f1f1f1;
  --background-alt: #e4e4e4;
  --dark: #0e0e0e;
  --text: #696969;
  --text-light: #c0c0c0;
  --error: rgb(231, 29, 29);
  --primary-font:'Arimo', sans-serif;
  --card-color1-variant: #FFF5EE;
  --card-color1: #F5F5DC;
  --card-color2-variant: #FAF0E6;
  --card-color2: #FFFAFA;
  --icon-color: #f50;
  --icon-color-alt: #ff5087;
  --submitted: #39bb06;
}


html,
body, #root {
    font-family: var(--primary-font);
    width: 100%;
    height: 100%;
    -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.App {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    min-height: 100%;
    height: auto;
    overflow: hidden;
}
