.sidebar-container {
    position: fixed;
    top: 0;
    left: 0;

    width: 100%;
    height: 100%;


    z-index: 1000;
    background-color: rgba(0,0,0,0.3);
    transition: background-color 0.2s ease 0.2s, transform 0.2s ease;
    
}

.sidebar-container.none {
    background-color: rgba(0,0,0,0);
    transform: translateX(-100%);
    transition: background-color 0.15s ease, transform 0.5s ease 0.2s;
}

.sidebar-bar {
    position: fixed;
    width: 80%;
    height: 100%;
    max-width: 500px;
    background-color: var(--background);
}

.sidebar-bar > p {
    padding: 15px;
    color: #000;
    margin: 0;
    cursor: pointer;
}

.sidebar-bar > p:first-child {
    margin-top: 20px;
}

.sidebar-bar > p:hover {
    color: var(--primary);
    background-color: var(--background-alt);
}

.sidebar-bar > p:focus {
    color: var(--primary);
    background-color: var(--background-alt);
}