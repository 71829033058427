.job-container {
    margin-top: 15px;
    margin-bottom: 15px;
    background-color: var(--card-color1);
}

.job-header {
    display: flex;
    padding: 3px;
    margin: 1px;
    background-color: var(--card-color1-variant);
    align-items: center;
}

.job-header > p {
    font-size: 0.8rem;
    color: black;
    margin: 0;
    text-align: center;
    flex: 1;
}

.edit-icon {
    margin: 3px;
    font-size: 1.2rem;
    color: var(--icon-color);
}

p.submitted {
    font-weight: bold;
    color: var(--submitted);
}

.copy-icon {
    margin: 3px;
    font-size: 1.2rem;
    color: var(--icon-color);
}

.trash-icon {
    margin: 3px;
    font-size: 1.2rem;
    color: var(--icon-color);
}

.job-header > svg.submitted{
    color: gray;
}

.job-header > svg:hover {
    color: var(--icon-color-alt);
}

.job-body {
    font-size: 0.8rem;
}

.job-body:active {
    background-color: #ffffe4;
}

.job-body > table {
    table-layout: fixed;
    width: 100%;
}

.job-left, .job-right {
    padding: 5px;
}

td.job-left {
    min-width: 50%;
}

td.job-right {
}

.job-body table tr {
    display: flex;
}

.job-body table tr td p {
   overflow: auto;
}

.job-body table tr td p span {
    font-weight: bold;
}