.uploader-container {
    position: fixed;
    top: 0;
    left: 0;

    width: 100%;
    height: 100%;

    align-items: center;
    justify-content: center;

    display: flex;
    flex-direction: column;
    background-color: rgba(0, 0, 0, 0.20);
    
    opacity: 1;
    z-index: 10;
    transition: all 0.5s ease;
}

.uploader-container.hidden {
    z-index: -1;
    opacity: 0;
    transition: opacity 0.5s ease, z-index 1s ease 1s;
}

.uploader-header  {
    display: flex;
    justify-content: center;
}
.uploader-header h4 {
    margin: 5px;
    color: #51a0d5;
}

.uploader-card {
    display: flex;
    flex-direction: column;
    padding: 2%;
    background-color: #ffffffd7;
    border-radius: 10px;
    width: 80%;
    max-width: 300px;

    box-shadow: 0px 0px 8px 1px rgba(0,0,0,0.5);
}

.uploader-body > input[type="file"] {
    display: none;
}

.uploader-chooser {
    position: relative;
    display: flex;
    flex-grow: 1;
    align-items: center;
    font-family: var(--base-font);
    padding: 5px;
    background-color: #ccc;
}

.uploader-chooser > p {
    margin: 0;
}

.uploader-body > input[type="text"] {
    border: none;
    outline: none;
    background-color: #ccc;
    padding: 5px;
    width: 100%;
}

.uploader-footer {
    justify-content: space-evenly;
    padding: 5px;
    display: flex;
}

.uploader-button {
    width: 100%;
    margin: 3px;
    background-color: #ffffffd7;
    border: 1px solid #51a0d5;
    border-radius: 10px;
    color: #51a0d5;
}

.uploader-button:hover {
    background-color: #51a0d5;
    color: #ffffffd7;
}

.uploader-progress {
    position: absolute;
    top:0;
    left: 0;

    display: flex;
    flex-direction: row;
    height: 100%;
    background-color: rgba(102, 255, 0, 0.719);
    transition: all 0.4s ease-in;
}