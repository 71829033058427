.button-container {
    color: var(--background);
    font-weight: bold;
    padding: 10px;
    text-align: center;
    background-color: var(--primary);
    cursor: pointer;
}

.button-container:hover {
    background-color: var(--primary-variant);
}