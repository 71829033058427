.load-container {
    display: flex;
    flex-direction: column;

    height: 1px;
    flex: 1;

    color: var(--text);
    background-color: var(--background);
    /* max-height: 100vh; */
}

.load-header {
    display: flex;
    flex-direction: column;
    background-color: #fafafa;
    margin-top: 10px;
    margin-bottom: 10px;
    padding: 5px;
    box-shadow: 0px 5px 9px 0px rgba(0,0,0,0.5);
}

.load-header > h3 {
    align-self: center;
    margin: 3px;
}

.load-header > table {
    table-layout: fixed;
    width: 100%;
}

.load-header p{
    overflow: auto;
    font-weight: bold;
    margin: 3px;
}

.load-container span {
    font-weight: normal;
}

td.load-left {
    min-width: 50%;
}

