.settings-container {
    display: flex;
    flex-direction: column;

    flex: 1;

    color: var(--text);
    background-color: var(--background);

    cursor: pointer;
}

.settings-item {
    color: var(--primary);
    display: flex;
    background-color: #fff;
    border-bottom: 1px solid #ddd  ;
    padding: 15px;
    align-items: center;
}

.settings-item-icon {
    padding: 5px;
    font-size: 1.6rem;
}

.settings-item > p {
    margin: 0;
    color: black;
    padding: 5px;
    margin-left: 15px;
}

.settings-item-chevron {
    margin-left: auto;
    color: var(--text-light);
}