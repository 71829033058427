.ND-container {
    position: fixed;
    top: 0;
    left: 0;

    width: 100%;
    height: 100%;


    z-index: 1000;
    background-color: rgba(0,0,0,0.3);

    display: flex;
    align-items: center;
    justify-content: center;
    
    opacity: 1;
    transition: opacity 0.2s ease-in;
}

.ND-container.hide {
    opacity: 0;
    z-index: -1000;
    transition: opacity 0.2s ease-out, z-index 0.1s linear 0.3s;
}

.ND-card {
    display: flex;
    flex-direction: column;
    width: 85%;
    max-width: 500px;
    padding: 10px;
    background-color: var(--background);
    border-radius: 10px;

    
}

.ND-card-header {
    color: black;
    display: flex;
    position: relative;
}

.ND-icon {
    font-size: 1.8rem;
    position: absolute;
    top: 10px;
    right: 10px;
    color: var(--primary);
}

.ND-card-body {
    display: flex;
    flex-direction: column;
}

.ND-card-body > label {
    font-weight: bold;
    font-size: 0.6rem;
    margin: 2px;

} 

.ND-card-body > input {
    outline: none;
    border: none;
    background-color: var(--background);
    padding: 5px;
    margin-bottom: 5px;
    border-bottom: 1px solid rgb(160, 160, 160);
    font-weight: bold;
    font-family: var(--primary-font);
}

.ND-card-body >input::placeholder {
    font-weight: bold;
    color: var(--text-light);
    font-family: var(--primary-font);
}

.ND-btn {
    background-color: var(--background);
    color: var(--primary);
    border: 2px solid var(--primary);
    border-radius: 10px;
}

.ND-btn:hover {
    background-color: var(--primary);
    color: var(--background);
}

