.tt-container {
    position: fixed;
    top: 0;
    left: 0;

    width: 100%;
    height: 100%;

    align-items: center;
    justify-content: center;

    display: flex;
    flex-direction: column;
    background-color: rgba(0, 0, 0, 0.20);
    
    opacity: 1;
    z-index: 10;
    transition: all 0.5s ease;
}

.tt-container.hidden {
    z-index: -1;
    opacity: 0;
    transition: opacity 0.5s ease, z-index 1s ease 1s;
}

.tt-header  {
    display: flex;
    justify-content: center;
}
.tt-header h4 {
    margin: 5px;
    color: #51a0d5;
}

.tt-card {
    display: flex;
    flex-direction: column;
    padding: 2%;
    background-color: #ffffffd7;
    border-radius: 10px;
    width: 80%;
    max-width: 300px;

    box-shadow: 0px 0px 8px 1px rgba(0,0,0,0.5);
}

.tt-body {
    display: flex;
}

.tt-dropdown {
    display: flex;
    flex-direction: column;
    flex: 1;
    padding: 5px;
}

.tt-dropdown label {
    font-weight: bold;
    font-size: 0.8rem;
    padding: 3px;
}

.tt-dropdown select {
    padding: 3px;
    font-family: var(--primary-font);
    border: 1px solid #51a0d5;
    background-color: #ffffffd7;
    border-radius: 10px;
}

.tt-footer {
    justify-content: space-evenly;
    padding: 5px;
    display: flex;
}

.tt-button {
    width: 100%;
    margin: 3px;
    background-color: #ffffffd7;
    border: 1px solid #51a0d5;
    border-radius: 10px;
    color: #51a0d5;
}

.tt-button:hover {
    background-color: #51a0d5;
    color: #ffffffd7;
}