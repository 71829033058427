.footer-container {
    display: flex;
    background-color: #fafafa;
    border-top: 1px solid rgba(204, 204, 204, 0.452);
    margin-top: auto;
}

.footer-icon-container {
    display: flex;
    flex-direction: column;
    flex: 1;
    padding: 5px;
    justify-content: center;
    align-items: center;
    color: var(--primary);
    cursor: pointer;
}

.load-list-container {
    overflow-y: scroll;
}

.footer-icon-container:active {
    background-color: #dfdfdf;
}

.footer-icon-container > p {
    margin: 3px;
    font-weight: bold;
    font-size: 0.8rem;
}

.footer-submit {
    font-size: 1.2rem;
}

.footer-camera {
    font-size: 1.2rem;
}

.footer-add {
    font-size: 1.3rem;
}