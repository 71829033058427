.tw-container {
    position: fixed;
    top: 0;
    left: 0;

    display: flex;
    width: 100%;
    height: 100%;
    background-color: rgba(29, 29, 29, 0.479);

    justify-content: center;
    align-items: center;
}

.tw-container.hidden {
    display: none;
}

.tw-card {
    display: flex;
    flex-direction: column;
    background-color: #fafafa;
    width: 90%;
    height: 60%;
    max-height: 750px;
    max-width: 500px;
}

.tw-header {
    display: flex;
    background-color: var(--primary-variant);
    background-color: rgb(33, 100, 245);
    padding: 6%;
    max-height: 30%;
    justify-content: center;
    align-items: center;
    color: #fafafa;
    font-size: 1.9rem;
}

.tw-header h2 {
    margin: 0;
    text-align: center;
}

.tw-meridiem {
    display: flex;
    flex-direction: column;
}

.tw-meridiem > p {
    font-size: 1.2rem;
    margin: 3px;
    cursor: pointer;
    color: rgba(219, 219, 219, 0.63);
}

.tw-meridiem > p.selected {
    color: #fafafa
}

.tw-card > svg {
    align-self: center;
    margin-top: auto;
    margin-bottom: auto;
    width: 80%;
    height: 70%;
}

 circle {
    pointer-events: all;
}

[id="Frame 1"]  g  circle {
    cursor: pointer;
    fill: #ddd;
}

[id="Frame 1"]  g circle.selected, [id="Frame 1"]  g path.selected {
    fill: var(--primary);
    stroke: var(--primary);
    transition: all 0.5s ease;
}

.tw-done {
    text-align: right;
    cursor: pointer;
    padding: 10px;
    font-weight: bold;
    color: rgb(33, 100, 245);
}

.tw-done:hover {
    color: var(--primary-variant);
}
