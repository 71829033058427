.loadI-container {
    margin-top: 15px;
    margin-bottom: 15px;
    background-color: var(--card-color2);
}

.loadI-header {
    display: flex;
    padding: 3px;
    margin: 1px;
    background-color: var(--card-color2-variant);
    align-items: center;
}

.loadI-header > p {
    font-size: 0.8rem;
    color: black;
    margin: 0;
    text-align: center;
    flex: 1;
    font-weight: bold;
}

.loadI-body {
    display: flex;
    width: 100%;
    overflow: scroll;
    padding: 5px;
}

.loadI-body > img{
    width: 150px;
    height: 150px;
    padding: 5px;

    cursor: pointer;
}