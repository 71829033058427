.iv-container {
    position: fixed;
    top: 0;
    left: 0;

    width: 100%;
    height: 100%;

    align-items: center;
    justify-content: center;

    display: flex;
    flex-direction: column;
    background-color: rgba(0, 0, 0, 0.20);
    
    opacity: 1;
    z-index: 10;
    transition: all 0.5s ease;
}

.iv-container.hidden {
    z-index: -1;
    opacity: 0;
    transition: opacity 0.5s ease, z-index 1s ease 1s;
}

.iv-card {
    position: relative;
    max-width: 500px;
    max-height: 80%;
    width: 90%;
}

.iv-card img {
    width: 100%;
}

.iv-controls {
    position: absolute;
    bottom: 5px;
    display: flex;

    justify-content: space-between;
    width: 100%;
    padding: 5px;
    color: white;
    font-size: 1.5rem;
}