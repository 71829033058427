@import url(https://fonts.googleapis.com/css2?family=Arimo:wght@400;500;700&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

html {
  box-sizing: border-box;
}
*, *:before, *:after {
  box-sizing: inherit;
}
body{

  --primary: #4390bc;
  --secondary: #3d789b;
  --primary-variant: #3d789b;
  --secondary-variant: #451AED;
  --highlight: #2086c2;
  --background: #f1f1f1;
  --background-alt: #e4e4e4;
  --dark: #0e0e0e;
  --text: #696969;
  --text-light: #c0c0c0;
  --error: rgb(231, 29, 29);
  --primary-font:'Arimo', sans-serif;
  --card-color1-variant: #FFF5EE;
  --card-color1: #F5F5DC;
  --card-color2-variant: #FAF0E6;
  --card-color2: #FFFAFA;
  --icon-color: #f50;
  --icon-color-alt: #ff5087;
  --submitted: #39bb06;
}


html,
body, #root {
    font-family: var(--primary-font);
    width: 100%;
    height: 100%;
  box-sizing: border-box;
}

.App {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    min-height: 100%;
    height: auto;
    overflow: hidden;
}

.login-container {
    display: flex;
    flex-direction: column;
    flex: 1 1;

    justify-content: center;
    color: var(--text);
    background-color: var(--background);
}

.login-container > img {
    width: 50%;
    max-width: 300px;
    align-self: center;
}

.login-card {
    display: flex;
    flex-direction: column;
    min-width: 200px;
    height: auto;
    width: 60%;
    background-color: var(--background);
    padding: 10px;
    margin-left: auto;
    margin-right: auto;
}

.login-card-header, .login-card-footer {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.login-card-header > h3 {
    color: var(--primary);
}

.login-card-main {
    display: flex;
    flex-direction: column;
}

.login-card-main > label {
    color: var(--text);
    font-weight: bold;
    padding: 5px;
}

.login-card-main > input {
    margin: 5px;
    padding: 10px;
    font-size: 1.1rem;
    outline: none;
    border: 2px solid var(--primary);
    border-radius: 5px;
    background-color: var(--background);
    color: var(--text);
}

.login-card-main > input::-webkit-input-placeholder {
    color: var(--text-light);
}

.login-card-main > input:-ms-input-placeholder {
    color: var(--text-light);
}

.login-card-main > input::placeholder {
    color: var(--text-light);
}

 .login-button {
    margin-top: 5px;
    align-self: center;
}

.login-card-main > input:focus {
    border: 1px solid var(--highlight);
}


.login-link {
    color: #000;
    font-weight: bold;
}


.login-link:hover {
    color: var(--highlight);
    cursor: pointer;
}

.login-error {
    color: var(--error);
}

@media only screen and (max-width: 600px) {
    .login-card {
        width: 90%;
    }
  }
.button-container {
    color: var(--background);
    font-weight: bold;
    padding: 10px;
    text-align: center;
    background-color: var(--primary);
    cursor: pointer;
}

.button-container:hover {
    background-color: var(--primary-variant);
}
.nav-container {
    display: flex;
    position: relative;
    color: #fff;
    align-items: center;
    background-color: var(--primary);
    font-family: var(--primary-font);
    font-weight: bold;
    font-size: 1.2rem;
}

.nav-container.hide {
    display: none;
}

.nav-item {
    display: flex;
    /* padding: 5px; */
}

.nav-item.header {
    margin-left: auto;
    margin-right: auto;
}

.nav-item.footer {
    font-size: 2rem;
    margin-right: 10px;
    /* margin-top: 10px; */
    cursor: pointer;
    position: absolute;
    right: 0;
}

.nav-item.footer:hover {
    color: #fff;
}
.sidebar-container {
    position: fixed;
    top: 0;
    left: 0;

    width: 100%;
    height: 100%;


    z-index: 1000;
    background-color: rgba(0,0,0,0.3);
    transition: background-color 0.2s ease 0.2s, transform 0.2s ease;
    
}

.sidebar-container.none {
    background-color: rgba(0,0,0,0);
    transform: translateX(-100%);
    transition: background-color 0.15s ease, transform 0.5s ease 0.2s;
}

.sidebar-bar {
    position: fixed;
    width: 80%;
    height: 100%;
    max-width: 500px;
    background-color: var(--background);
}

.sidebar-bar > p {
    padding: 15px;
    color: #000;
    margin: 0;
    cursor: pointer;
}

.sidebar-bar > p:first-child {
    margin-top: 20px;
}

.sidebar-bar > p:hover {
    color: var(--primary);
    background-color: var(--background-alt);
}

.sidebar-bar > p:focus {
    color: var(--primary);
    background-color: var(--background-alt);
}
.signup-container {
    display: flex;
    flex-direction: column;
    flex: 1 1;

    justify-content: center;
    color: var(--text);
    background-color: var(--background);
}

.signup-card {
    display: flex;
    flex-direction: column;
    min-width: 200px;
    height: auto;
    width: 60%;
    background-color: var(--background);
    padding: 10px;
    margin-left: auto;
    margin-right: auto;
}

.signup-card-header, .signup-card-footer {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.signup-card-header > h3 {
    color: var(--primary);
}

.signup-card-main {
    display: flex;
    flex-direction: column;
}

.signup-card-main > label {
    color: var(--text);
    font-weight: bold;
    padding: 5px;
}

.signup-card-main > input {
    margin: 5px;
    padding: 10px;
    font-size: 1.1rem;
    outline: none;
    border: 2px solid var(--primary);
    border-radius: 5px;
    background-color: var(--background);
    color: var(--text);
}

.signup-btn-container {
    display: flex;
}

 .signup-button {
    align-self: center;
    margin: 2px;
}

.signup-card-main > input:focus {
    border: 1px solid var(--highlight);
}

.signup-card-main > input::-webkit-input-placeholder {
    color: var(--text-light);
}

.signup-card-main > input:-ms-input-placeholder {
    color: var(--text-light);
}

.signup-card-main > input::placeholder {
    color: var(--text-light);
}

.signup-link:hover {
    color: var(--highlight);
    cursor: pointer;
}

.signup-error {
    color: var(--error);
}

@media only screen and (max-width: 600px) {
    .signup-card {
        width: 90%;
    }
  }
.home-container {
    display: flex;
    flex-direction: column;

    flex: 1 1;

    color: var(--text);
    background-color: var(--background);
}

.home-card {
    flex: 1 1;
    position: relative;
    border-radius: 5px;
    box-shadow: 0px 5px 18px 0px rgba(0,0,0,0.5);
    background: rgb(222,225,225);
    background: linear-gradient(180deg, rgba(222,225,225,1) 0%, rgba(244,244,244,1) 100%);
    margin: 3%;

    align-self: center;
    width: 90%;
    max-width: 500px;
    
}

.home-card-header {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-bottom: 1px solid #ccc;
    padding: 10px;
    margin-bottom: 10px;
}

.home-card-header > h1 {
    color: var(--primary);
}

.home-card-main {
    display: flex;
    flex-direction: column;
    padding: 10px;
}

.home-card-title {
    color: #000;
    margin-top: 10px;
    font-weight: bold;
}

.home-card-info {
    margin-left: 5px;
    font-size: 0.9rem;
}

.home-card > img {
    position: absolute;
    right: 0;
    bottom: 0;
    margin: 10px;
    max-width: 75px;
}

.home-buttons {
    display: flex;
    align-self: center;
    

    align-self: center;
    width: 90%;
    max-width: 500px;
}

.home-btn {
    margin: 5px;
    align-self: center;
}
.ND-container {
    position: fixed;
    top: 0;
    left: 0;

    width: 100%;
    height: 100%;


    z-index: 1000;
    background-color: rgba(0,0,0,0.3);

    display: flex;
    align-items: center;
    justify-content: center;
    
    opacity: 1;
    transition: opacity 0.2s ease-in;
}

.ND-container.hide {
    opacity: 0;
    z-index: -1000;
    transition: opacity 0.2s ease-out, z-index 0.1s linear 0.3s;
}

.ND-card {
    display: flex;
    flex-direction: column;
    width: 85%;
    max-width: 500px;
    padding: 10px;
    background-color: var(--background);
    border-radius: 10px;

    
}

.ND-card-header {
    color: black;
    display: flex;
    position: relative;
}

.ND-icon {
    font-size: 1.8rem;
    position: absolute;
    top: 10px;
    right: 10px;
    color: var(--primary);
}

.ND-card-body {
    display: flex;
    flex-direction: column;
}

.ND-card-body > label {
    font-weight: bold;
    font-size: 0.6rem;
    margin: 2px;

} 

.ND-card-body > input {
    outline: none;
    border: none;
    background-color: var(--background);
    padding: 5px;
    margin-bottom: 5px;
    border-bottom: 1px solid rgb(160, 160, 160);
    font-weight: bold;
    font-family: var(--primary-font);
}

.ND-card-body >input::-webkit-input-placeholder {
    font-weight: bold;
    color: var(--text-light);
    font-family: var(--primary-font);
}

.ND-card-body >input:-ms-input-placeholder {
    font-weight: bold;
    color: var(--text-light);
    font-family: var(--primary-font);
}

.ND-card-body >input::placeholder {
    font-weight: bold;
    color: var(--text-light);
    font-family: var(--primary-font);
}

.ND-btn {
    background-color: var(--background);
    color: var(--primary);
    border: 2px solid var(--primary);
    border-radius: 10px;
}

.ND-btn:hover {
    background-color: var(--primary);
    color: var(--background);
}


.review-container {
    display: flex;
    flex-direction: column;

    height: 1px;
    flex: 1 1;

    color: var(--text);
    background-color: var(--background);
    /* max-height: 100vh; */
}

.review-list-container {
    height: auto;
    overflow: auto;
}

.review-floating-btn {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    background-color: var(--primary);
    color: #fff;
    bottom: 16px;
    right: 16px;
    font-size: 2.5rem;
}

.review-floating-btn:hover{
    background-color: var(--primary-variant);
}
.job-container {
    margin-top: 15px;
    margin-bottom: 15px;
    background-color: var(--card-color1);
}

.job-header {
    display: flex;
    padding: 3px;
    margin: 1px;
    background-color: var(--card-color1-variant);
    align-items: center;
}

.job-header > p {
    font-size: 0.8rem;
    color: black;
    margin: 0;
    text-align: center;
    flex: 1 1;
}

.edit-icon {
    margin: 3px;
    font-size: 1.2rem;
    color: var(--icon-color);
}

p.submitted {
    font-weight: bold;
    color: var(--submitted);
}

.copy-icon {
    margin: 3px;
    font-size: 1.2rem;
    color: var(--icon-color);
}

.trash-icon {
    margin: 3px;
    font-size: 1.2rem;
    color: var(--icon-color);
}

.job-header > svg.submitted{
    color: gray;
}

.job-header > svg:hover {
    color: var(--icon-color-alt);
}

.job-body {
    font-size: 0.8rem;
}

.job-body:active {
    background-color: #ffffe4;
}

.job-body > table {
    table-layout: fixed;
    width: 100%;
}

.job-left, .job-right {
    padding: 5px;
}

td.job-left {
    min-width: 50%;
}

td.job-right {
}

.job-body table tr {
    display: flex;
}

.job-body table tr td p {
   overflow: auto;
}

.job-body table tr td p span {
    font-weight: bold;
}
.dd-container {
    background-color: #fafafa;
    border: 1px solid black;
    margin: 2px;
    padding: 5px;
    display: flex;
    flex-direction: column;
    
}

.dd-container > label {
    font-weight: bold;
    color: #000;
    font-size: 0.7rem;
}

.dd-container > select {
    font-family: var(--primary-font);
    font-weight: bold;
    margin: 2px;
    border: 1px solid #ccc;
    padding: 5px;
    border-radius: 5px;
}


.load-container {
    display: flex;
    flex-direction: column;

    height: 1px;
    flex: 1 1;

    color: var(--text);
    background-color: var(--background);
    /* max-height: 100vh; */
}

.load-header {
    display: flex;
    flex-direction: column;
    background-color: #fafafa;
    margin-top: 10px;
    margin-bottom: 10px;
    padding: 5px;
    box-shadow: 0px 5px 9px 0px rgba(0,0,0,0.5);
}

.load-header > h3 {
    align-self: center;
    margin: 3px;
}

.load-header > table {
    table-layout: fixed;
    width: 100%;
}

.load-header p{
    overflow: auto;
    font-weight: bold;
    margin: 3px;
}

.load-container span {
    font-weight: normal;
}

td.load-left {
    min-width: 50%;
}


.li-container {
    margin-top: 15px;
    margin-bottom: 15px;
    background-color: var(--card-color2);
}

.li-header {
    display: flex;
    padding: 3px;
    margin: 1px;
    background-color: var(--card-color2-variant);
    align-items: center;
}

.li-header > p {
    font-size: 0.8rem;
    color: black;
    margin: 0;
    text-align: center;
    flex: 1 1;
}

.edit-icon {
    margin: 3px;
    font-size: 1.2rem;
    color: var(--icon-color);
}

p.submitted {
    font-weight: bold;
    color: var(--submitted);
}

.copy-icon {
    margin: 3px;
    font-size: 1.2rem;
    color: var(--icon-color);
}

.trash-icon {
    margin: 3px;
    font-size: 1.2rem;
    color: var(--icon-color);
}

.li-header > svg.submitted{
    color: gray;
}

.li-header > svg:hover {
    color: var(--icon-color-alt);
}

.li-body {
    font-size: 0.8rem;
}

.li-body:active {
    background-color: #ffffe4;
}

.li-body > table {
    table-layout: fixed;
    width: 100%;
}

.li-left, .li-right {
    padding: 5px;
}

td.li-left {
    min-width: 50%;
}

td.li-right {
}

.li-body table tr {
    display: flex;
}

.li-body table tr td p {
   overflow: auto;
}

.li-body table tr td p span {
    font-weight: bold;
}
.footer-container {
    display: flex;
    background-color: #fafafa;
    border-top: 1px solid rgba(204, 204, 204, 0.452);
    margin-top: auto;
}

.footer-icon-container {
    display: flex;
    flex-direction: column;
    flex: 1 1;
    padding: 5px;
    justify-content: center;
    align-items: center;
    color: var(--primary);
    cursor: pointer;
}

.load-list-container {
    overflow-y: scroll;
}

.footer-icon-container:active {
    background-color: #dfdfdf;
}

.footer-icon-container > p {
    margin: 3px;
    font-weight: bold;
    font-size: 0.8rem;
}

.footer-submit {
    font-size: 1.2rem;
}

.footer-camera {
    font-size: 1.2rem;
}

.footer-add {
    font-size: 1.3rem;
}
.ND-error {
    padding: 3px;
    color: var(--error);
    font-size: 0.8rem;
    font-weight: 600;
}
.timepicker-container {
    display: flex;
    flex-direction: column;
}

.timepicker-container > label{
 font-weight: bold;
    font-size: 0.6rem;
    margin: 2px;
}

.timepicker-input-area {
    display: flex;
    align-items: center;
}



.timepicker-input-area > input {
    outline: none;
    border: none;
    background-color: var(--background);
    padding: 5px;
    margin-bottom: 5px;
    border-bottom: 1px solid rgb(160, 160, 160);
    font-weight: bold;
    font-family: var(--primary-font);
    width: 100%;
}

.timepicker-input-area >input::-webkit-input-placeholder {
    font-weight: bold;
    color: var(--text-light);
    font-family: var(--primary-font);
}

.timepicker-input-area >input:-ms-input-placeholder {
    font-weight: bold;
    color: var(--text-light);
    font-family: var(--primary-font);
}

.timepicker-input-area >input::placeholder {
    font-weight: bold;
    color: var(--text-light);
    font-family: var(--primary-font);
}

.timepicker-input-area > svg {
    font-size: 2rem;
    padding: 5px;
    border: 2px solid var(--primary-variant);
    color: var(--primary-variant);
    border-radius: 5px;
    margin-bottom: 3px;
    margin-left: 3px;
}

.timepicker-input-area > svg:active {
    background-color: #ccc;
}



.tw-container {
    position: fixed;
    top: 0;
    left: 0;

    display: flex;
    width: 100%;
    height: 100%;
    background-color: rgba(29, 29, 29, 0.479);

    justify-content: center;
    align-items: center;
}

.tw-container.hidden {
    display: none;
}

.tw-card {
    display: flex;
    flex-direction: column;
    background-color: #fafafa;
    width: 90%;
    height: 60%;
    max-height: 750px;
    max-width: 500px;
}

.tw-header {
    display: flex;
    background-color: var(--primary-variant);
    background-color: rgb(33, 100, 245);
    padding: 6%;
    max-height: 30%;
    justify-content: center;
    align-items: center;
    color: #fafafa;
    font-size: 1.9rem;
}

.tw-header h2 {
    margin: 0;
    text-align: center;
}

.tw-meridiem {
    display: flex;
    flex-direction: column;
}

.tw-meridiem > p {
    font-size: 1.2rem;
    margin: 3px;
    cursor: pointer;
    color: rgba(219, 219, 219, 0.63);
}

.tw-meridiem > p.selected {
    color: #fafafa
}

.tw-card > svg {
    align-self: center;
    margin-top: auto;
    margin-bottom: auto;
    width: 80%;
    height: 70%;
}

 circle {
    pointer-events: all;
}

[id="Frame 1"]  g  circle {
    cursor: pointer;
    fill: #ddd;
}

[id="Frame 1"]  g circle.selected, [id="Frame 1"]  g path.selected {
    fill: var(--primary);
    stroke: var(--primary);
    transition: all 0.5s ease;
}

.tw-done {
    text-align: right;
    cursor: pointer;
    padding: 10px;
    font-weight: bold;
    color: rgb(33, 100, 245);
}

.tw-done:hover {
    color: var(--primary-variant);
}

.loadI-container {
    margin-top: 15px;
    margin-bottom: 15px;
    background-color: var(--card-color2);
}

.loadI-header {
    display: flex;
    padding: 3px;
    margin: 1px;
    background-color: var(--card-color2-variant);
    align-items: center;
}

.loadI-header > p {
    font-size: 0.8rem;
    color: black;
    margin: 0;
    text-align: center;
    flex: 1 1;
    font-weight: bold;
}

.loadI-body {
    display: flex;
    width: 100%;
    overflow: scroll;
    padding: 5px;
}

.loadI-body > img{
    width: 150px;
    height: 150px;
    padding: 5px;

    cursor: pointer;
}
.iv-container {
    position: fixed;
    top: 0;
    left: 0;

    width: 100%;
    height: 100%;

    align-items: center;
    justify-content: center;

    display: flex;
    flex-direction: column;
    background-color: rgba(0, 0, 0, 0.20);
    
    opacity: 1;
    z-index: 10;
    transition: all 0.5s ease;
}

.iv-container.hidden {
    z-index: -1;
    opacity: 0;
    transition: opacity 0.5s ease, z-index 1s ease 1s;
}

.iv-card {
    position: relative;
    max-width: 500px;
    max-height: 80%;
    width: 90%;
}

.iv-card img {
    width: 100%;
}

.iv-controls {
    position: absolute;
    bottom: 5px;
    display: flex;

    justify-content: space-between;
    width: 100%;
    padding: 5px;
    color: white;
    font-size: 1.5rem;
}
.uploader-container {
    position: fixed;
    top: 0;
    left: 0;

    width: 100%;
    height: 100%;

    align-items: center;
    justify-content: center;

    display: flex;
    flex-direction: column;
    background-color: rgba(0, 0, 0, 0.20);
    
    opacity: 1;
    z-index: 10;
    transition: all 0.5s ease;
}

.uploader-container.hidden {
    z-index: -1;
    opacity: 0;
    transition: opacity 0.5s ease, z-index 1s ease 1s;
}

.uploader-header  {
    display: flex;
    justify-content: center;
}
.uploader-header h4 {
    margin: 5px;
    color: #51a0d5;
}

.uploader-card {
    display: flex;
    flex-direction: column;
    padding: 2%;
    background-color: #ffffffd7;
    border-radius: 10px;
    width: 80%;
    max-width: 300px;

    box-shadow: 0px 0px 8px 1px rgba(0,0,0,0.5);
}

.uploader-body > input[type="file"] {
    display: none;
}

.uploader-chooser {
    position: relative;
    display: flex;
    flex-grow: 1;
    align-items: center;
    font-family: var(--base-font);
    padding: 5px;
    background-color: #ccc;
}

.uploader-chooser > p {
    margin: 0;
}

.uploader-body > input[type="text"] {
    border: none;
    outline: none;
    background-color: #ccc;
    padding: 5px;
    width: 100%;
}

.uploader-footer {
    justify-content: space-evenly;
    padding: 5px;
    display: flex;
}

.uploader-button {
    width: 100%;
    margin: 3px;
    background-color: #ffffffd7;
    border: 1px solid #51a0d5;
    border-radius: 10px;
    color: #51a0d5;
}

.uploader-button:hover {
    background-color: #51a0d5;
    color: #ffffffd7;
}

.uploader-progress {
    position: absolute;
    top:0;
    left: 0;

    display: flex;
    flex-direction: row;
    height: 100%;
    background-color: rgba(102, 255, 0, 0.719);
    transition: all 0.4s ease-in;
}
.tt-container {
    position: fixed;
    top: 0;
    left: 0;

    width: 100%;
    height: 100%;

    align-items: center;
    justify-content: center;

    display: flex;
    flex-direction: column;
    background-color: rgba(0, 0, 0, 0.20);
    
    opacity: 1;
    z-index: 10;
    transition: all 0.5s ease;
}

.tt-container.hidden {
    z-index: -1;
    opacity: 0;
    transition: opacity 0.5s ease, z-index 1s ease 1s;
}

.tt-header  {
    display: flex;
    justify-content: center;
}
.tt-header h4 {
    margin: 5px;
    color: #51a0d5;
}

.tt-card {
    display: flex;
    flex-direction: column;
    padding: 2%;
    background-color: #ffffffd7;
    border-radius: 10px;
    width: 80%;
    max-width: 300px;

    box-shadow: 0px 0px 8px 1px rgba(0,0,0,0.5);
}

.tt-body {
    display: flex;
}

.tt-dropdown {
    display: flex;
    flex-direction: column;
    flex: 1 1;
    padding: 5px;
}

.tt-dropdown label {
    font-weight: bold;
    font-size: 0.8rem;
    padding: 3px;
}

.tt-dropdown select {
    padding: 3px;
    font-family: var(--primary-font);
    border: 1px solid #51a0d5;
    background-color: #ffffffd7;
    border-radius: 10px;
}

.tt-footer {
    justify-content: space-evenly;
    padding: 5px;
    display: flex;
}

.tt-button {
    width: 100%;
    margin: 3px;
    background-color: #ffffffd7;
    border: 1px solid #51a0d5;
    border-radius: 10px;
    color: #51a0d5;
}

.tt-button:hover {
    background-color: #51a0d5;
    color: #ffffffd7;
}
.settings-container {
    display: flex;
    flex-direction: column;

    flex: 1 1;

    color: var(--text);
    background-color: var(--background);

    cursor: pointer;
}

.settings-item {
    color: var(--primary);
    display: flex;
    background-color: #fff;
    border-bottom: 1px solid #ddd  ;
    padding: 15px;
    align-items: center;
}

.settings-item-icon {
    padding: 5px;
    font-size: 1.6rem;
}

.settings-item > p {
    margin: 0;
    color: black;
    padding: 5px;
    margin-left: 15px;
}

.settings-item-chevron {
    margin-left: auto;
    color: var(--text-light);
}
.toast-container {
    position: fixed;
    bottom: 0;
    left: 1%;

    margin-bottom: 10px;
    display: flex;
    flex-direction: column;
    background-image: linear-gradient(100deg,rgba(255, 255, 255, 0.89), rgba(215, 254, 255, 0.89), rgba(199, 255, 255, 0.5));
    padding: 5px;

    width: 40%;
    min-width: 250px;

    border-radius: 10px;
    transition: all 1s ease;
    z-index: 10;
}

.toast-container.hidden {
    opacity: 0;
    z-index: -1;
    transition: opacity 1s ease, z-index 1s ease 1s;
}

.toast-header {
    display: flex;
    color: var(--text);
    align-items: center;
    font-weight:  bold;
    font-size: 0.8rem;
}

.toast-header > p {
    margin: 0;
}

p.toast-close {
    padding: 5px;
    margin-right: 10px;
    cursor: pointer;
    color: black;
}

.toast-main {
    font-family: Consolas,monaco,monospace;
    font-size: 0.75rem; 
}
