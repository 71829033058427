.home-container {
    display: flex;
    flex-direction: column;

    flex: 1;

    color: var(--text);
    background-color: var(--background);
}

.home-card {
    flex: 1;
    position: relative;
    border-radius: 5px;
    box-shadow: 0px 5px 18px 0px rgba(0,0,0,0.5);
    background: rgb(222,225,225);
    background: linear-gradient(180deg, rgba(222,225,225,1) 0%, rgba(244,244,244,1) 100%);
    margin: 3%;

    align-self: center;
    width: 90%;
    max-width: 500px;
    
}

.home-card-header {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-bottom: 1px solid #ccc;
    padding: 10px;
    margin-bottom: 10px;
}

.home-card-header > h1 {
    color: var(--primary);
}

.home-card-main {
    display: flex;
    flex-direction: column;
    padding: 10px;
}

.home-card-title {
    color: #000;
    margin-top: 10px;
    font-weight: bold;
}

.home-card-info {
    margin-left: 5px;
    font-size: 0.9rem;
}

.home-card > img {
    position: absolute;
    right: 0;
    bottom: 0;
    margin: 10px;
    max-width: 75px;
}

.home-buttons {
    display: flex;
    align-self: center;
    

    align-self: center;
    width: 90%;
    max-width: 500px;
}

.home-btn {
    margin: 5px;
    align-self: center;
}