.review-container {
    display: flex;
    flex-direction: column;

    height: 1px;
    flex: 1;

    color: var(--text);
    background-color: var(--background);
    /* max-height: 100vh; */
}

.review-list-container {
    height: auto;
    overflow: auto;
}

.review-floating-btn {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    background-color: var(--primary);
    color: #fff;
    bottom: 16px;
    right: 16px;
    font-size: 2.5rem;
}

.review-floating-btn:hover{
    background-color: var(--primary-variant);
}